import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, useFormikContext } from 'formik'
import * as yup from 'yup'
import { Link } from 'gatsby'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import map from 'lodash/map'

import PATHS from 'routes/paths'
import scrollToDataId from 'lib/window/scroll-to-data-id'

import RainbowButton from 'components/RainbowButton'
import { Button, Wrapper, Grid, H } from 'components/common'
import Input from 'components/form/Input'
import Checkbox from 'components/form/Checkbox'
import ErrorSummary from 'components/form/ErrorSummary'
import SuccessHeading from 'components/SuccessHeading'
import Box from 'components/Box'
import { COLOR } from 'styles/tokens'

const PIXEL_PRIDE_SLACK_ZAP_URL =
  'https://hooks.zapier.com/hooks/catch/7432126/oig0uua'

const schema = yup.object().shape({
  firstName: yup.string().required('This field is required.'),
  lastName: yup.string().required('This field is required.'),
  email: yup
    .string()
    .email('Please enter a valid email.')
    .required('This field is required.'),
  details: yup.string().required('This field is required.'),
  terms: yup.boolean().oneOf([true], 'This field is required.'),
})

const InnerForm = ({ error }) => {
  const { errors, touched, isSubmitting } = useFormikContext()
  const visibleErrors = pick(errors, Object.keys(touched))
  const showErrorSummary = !isEmpty(visibleErrors)

  return (
    <Form>
      <Box>
        <Grid columns={1}>
          <Grid.Item span={1}>
            <Input name="firstName" label="First name" required />
          </Grid.Item>
          <Grid.Item span={1}>
            <Input name="lastName" label="Last name" required />
          </Grid.Item>
          <Grid.Item span={1}>
            <Input name="email" type="email" label="Email" required />
          </Grid.Item>
          <Grid.Item span={1}>
            <Input
              name="details"
              component="textarea"
              label="Tell us a bit about how you’d like get involved with Pixel Pride"
              required
            />
          </Grid.Item>
        </Grid>
      </Box>
      <Grid>
        <Grid.Item spanFromM={6} startColumnFromM={4}>
          <Checkbox
            name="terms"
            label={
              <>
                I agree to participate in accordance with the Pixel Pride{' '}
                <Link to={PATHS.CODE_OF_CONDUCT}>Code of Coduct</Link>
              </>
            }
            required
          />
          <Wrapper centered margin={['XL', 0, 0]}>
            {error && <ErrorSummary>{error}</ErrorSummary>}
            {showErrorSummary && (
              <ErrorSummary>Please complete all required fields.</ErrorSummary>
            )}
            <RainbowButton
              button={Button.Primary}
              type="submit"
              stretch
              loading={isSubmitting}
            >
              Request invite
            </RainbowButton>
          </Wrapper>
        </Grid.Item>
      </Grid>
    </Form>
  )
}

InnerForm.propTypes = {
  error: PropTypes.string,
}

InnerForm.defaultProps = {
  error: '',
}

const SignupForm = () => {
  const [error, setError] = useState(null)
  const [isSuccessful, setIsSuccessful] = useState(false)

  const handleSubmit = async (values) => {
    setError(null)

    const formData = new FormData()

    map(values, (value, key) => {
      formData.append(key, value)
    })

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    try {
      await axios.post(PIXEL_PRIDE_SLACK_ZAP_URL, formData, config)
      setIsSuccessful(true)
      scrollToDataId('form')
    } catch (e) {
      // eslint-disable-next-line
      Sentry.captureException(e)
      setError(
        'There was an a problem submitting the form. Please try again, or send us an email at hello@pixel-pond.com.'
      )
    }
  }

  return (
    <Wrapper background={COLOR.BACKGROUND.PALE} data-scroll-id="form">
      <Grid container>
        <Grid.Item spanFromL={8} startColumnFromL={3}>
          {!isSuccessful && (
            <>
              <H size="L" centered marginFromM={[0, 0, 'XL']}>
                Sign up to Pixel Pride on Slack
              </H>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  details: '',
                  terms: false,
                }}
                validationSchema={schema}
                onSubmit={handleSubmit}
              >
                <InnerForm error={error} />
              </Formik>
            </>
          )}
          {isSuccessful && (
            <Box>
              <Wrapper padding={['M', 0]} centered>
                <SuccessHeading size="L">
                  Thanks for requesting an invite!
                </SuccessHeading>
                <p>We’ll be in touch soon.</p>
              </Wrapper>
            </Box>
          )}
        </Grid.Item>
      </Grid>
    </Wrapper>
  )
}

export default SignupForm
