import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { COLOR, GTR } from 'styles/tokens'

const StyledList = styled.ul`
  margin-bottom: ${GTR.S};
`

const StyledListItem = styled.li`
  padding-left: ${GTR.M};

  &:before {
    content: '•';
    color: ${COLOR.ACCENT.PRIMARY};
    display: inline-block;
    width: ${GTR.M};
    margin-left: -${GTR.M};
  }
`

const BulletList = ({ items }) => {
  return (
    <StyledList>
      {items.map((item) => (
        <StyledListItem key={item}>{item}</StyledListItem>
      ))}
    </StyledList>
  )
}

BulletList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default BulletList
