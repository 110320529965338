import React from 'react'
import styled from 'styled-components'
import { Wrapper, H, Grid } from 'components/common'
import { COLOR } from 'styles/tokens'
import { graphql, useStaticQuery } from 'gatsby'

import RainbowButton from 'components/RainbowButton'
import Image from 'components/Image'
import CurveWrapper from 'components/CurveWrapper'
import { screenMax } from 'styles/helpers/responsive'

const LINKED_IN_URL = 'https://www.linkedin.com/groups/13731430'

const StyledImageGridItem = styled(Grid.Item)`
  ${screenMax.m`
    display: none;
  `}
`

const LinkedIn = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "nick-and-frederico-laughing.jpg" }) {
        ...fluidImage
      }
    }
  `)

  return (
    <CurveWrapper background={COLOR.ACCENT.PRIMARY} top={40}>
      <Grid container>
        <StyledImageGridItem spanFromL={6}>
          <Image
            sizes={{ ...image.childImageSharp.fluid, aspectRatio: 5 / 3 }}
          />
        </StyledImageGridItem>
        <Grid.Item
          spanFromL={5}
          startColumnFromL={8}
          style={{ alignSelf: 'center' }}
        >
          <H size="L">LinkedIn Group</H>
          <Wrapper margin={[0, 0, 'L']} marginFromL={[0, 0, 'XL']}>
            <p>Join our growing community on LinkedIn</p>
          </Wrapper>
          <RainbowButton
            href={LINKED_IN_URL}
            target="_blank"
            wide
            stretchMobile
          >
            Find out more
          </RainbowButton>
        </Grid.Item>
      </Grid>
    </CurveWrapper>
  )
}

export default LinkedIn
