import React from 'react'

import Layout from 'components/layout'
import SEO from 'components/seo'
import Hero from './Hero'
import Ethos from './Ethos'
import SignupForm from './SignupForm'
import LinkedIn from './LinkedIn'

const PixelPride = () => {
  return (
    <Layout>
      <SEO title="Pixel Pride" />
      <Hero />
      <Ethos />
      <SignupForm />
      <LinkedIn />
    </Layout>
  )
}

export default PixelPride
