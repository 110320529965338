import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import BulletList from 'components/BulletList'
import Image from 'components/Image'
import { Wrapper, H, Grid } from 'components/common'
import { COLOR } from 'styles/tokens'

const StyledWrapper = styled(Wrapper)`
  background-color: ${COLOR.GREY.DARK};
  color: ${COLOR.WHITE};
  position: relative;
`

const Hero = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "nick-with-stripy-shirt.jpg" }) {
        ...fluidImage
      }
    }
  `)

  return (
    <StyledWrapper>
      <Wrapper container>
        <H size="XL" centered margin={[0, 0, 'XL']} marginFromM={[0, 0, 'XXL']}>
          Pixel Pride
        </H>
        <Grid>
          <Grid.Item spanFromM={5}>
            <H size="L" color={COLOR.ACCENT.PRIMARY}>
              An open community
            </H>
            <p>
              Our goal is to grow our community of digital advocates who can
              donate expertise and time to support charities within the LGBTQ+
              community.
            </p>
            <p>This is a space to build community:</p>
            <BulletList
              items={[
                'Putting yourself forward for pro bono work',
                'Supporting charities within the LGBTQ+ community',
                'Asking other professionals for job advice',
                'Sharing inspiration',
                'Asking for feedback on your latest project',
                'Talking with other queer people in the industry',
              ]}
            />
          </Grid.Item>
          <Grid.Item spanFromM={6} startColumnFromM={7}>
            <Image fluid={image.childImageSharp.fluid} />
          </Grid.Item>
        </Grid>
      </Wrapper>
    </StyledWrapper>
  )
}

export default Hero
