import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Wrapper, Grid, H } from 'components/common'
import { BORDER, COLOR, GTR } from 'styles/tokens'
import PATHS from 'routes/paths'
import { screenMax } from 'styles/helpers/responsive'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import pixelPondLogo from 'images/pixel-pride-logo.jpg'

import CurveWrapper from 'components/CurveWrapper'

const ORANGE = '#FC7028'
const YELLOW = '#FEC855'
const GREEN = '#32DAC3'
const BLUE = '#24B4E2'
const PURPLE = '#D847B2'

const StyledCard = styled(motion.div)`
  background-color: ${COLOR.WHITE};
  padding: ${GTR.XL};
  border-radius: ${BORDER.RADIUS.M};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  ${screenMax.s`
    padding: ${GTR.M};
  `}
`

const StyledCardItem = styled(motion.div)``

const StyledLogo = styled.img`
  display: block;
  margin: 0 auto ${GTR.XL};

  ${screenMax.s`
    width: 100px;
  `}
`

const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  },
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const Ethos = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <CurveWrapper background={COLOR.WHITE} top={55}>
      <Wrapper container>
        <Grid>
          <Grid.Item
            spanFromM={10}
            startColumnFromM={2}
            spanFromL={8}
            startColumnFromL={3}
          >
            <StyledCard
              variants={container}
              initial="hidden"
              animate={controls}
              ref={ref}
            >
              <StyledCardItem variants={item}>
                <StyledLogo src={pixelPondLogo} alt="Pixel Pride" width={120} />
              </StyledCardItem>
              <StyledCardItem variants={item}>
                <H size="M" color={ORANGE}>
                  Professionalism
                </H>
                <p>
                  We are competent, accountable, reliable and responsible.
                  Interacting positively and collaborating with all the Pixel
                  Pride members and partners.
                </p>
              </StyledCardItem>
              <StyledCardItem variants={item}>
                <H size="M" color={YELLOW}>
                  Respect
                </H>
                <p>
                  We treat all others as you wish to be treated, being
                  courteous, kind and acting with consideration for others.
                </p>
              </StyledCardItem>
              <StyledCardItem variants={item}>
                <H size="M" color={GREEN}>
                  Integrity
                </H>
                <p>
                  We will be honest, trustworthy and ethical. We will always do
                  the right thing, and will not compromise on the truth.
                </p>
              </StyledCardItem>
              <StyledCardItem variants={item}>
                <H size="M" color={BLUE}>
                  Diversity
                </H>
                <p>
                  We celebrate differences in others, creating an environment of
                  equality and inclusion with opportunities for everyone.
                </p>
              </StyledCardItem>
              <StyledCardItem variants={item}>
                <H size="M" color={PURPLE}>
                  Excellence
                </H>
                <p>
                  We are dedicated, motivated, innovative and ent; giving our
                  best every day. We will encourage and support all Pixel Pride
                  members to excel in everything they do.
                </p>
              </StyledCardItem>
              <StyledCardItem variants={item}>
                <p style={{ marginBottom: 0 }}>
                  The Pixel Pride{' '}
                  <Link to={PATHS.CODE_OF_CONDUCT}>
                    <strong>Code of Conduct</strong>
                  </Link>{' '}
                  explains more about this community and the expectations of
                  members.
                </p>
              </StyledCardItem>
            </StyledCard>
          </Grid.Item>
        </Grid>
      </Wrapper>
    </CurveWrapper>
  )
}

export default Ethos
