import styled from 'styled-components'
import React from 'react'

import rainbowFlag from 'images/rainbow-background.svg'

import { Button } from 'components/common'
import { COLOR } from 'styles/tokens'

const RainbowButton = styled(({ button, ...rest }) => {
  const Element = button || Button.White
  // eslint-disable-next-line
  return <Element {...rest} />
})`
  transition: none;

  &:hover {
    // color: ${COLOR.WHITE};
    background-image: url('${rainbowFlag}');
    background-position: center;
    background-size: cover;
    border: none;
  }
`

export default RainbowButton
